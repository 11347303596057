<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>

  <div hidden v-html="SvgSprite" />
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { useSubscribesStore } from '@academica-box/composables/store/subscribes'
  import { useUserStore } from '@academica-box/composables/store/user'
  import { usePromoStore } from '@academica-box/composables/store/promo'
  import SvgSprite from '@/assets/svg/sprite.svg?raw'

  const route = useRoute()
  const config = useRuntimeConfig()
  const { $theme } = useNuxtApp()

  const subscribesStore = useSubscribesStore()
  const { isUserSubsriptionLoaded } = storeToRefs(subscribesStore)
  const userStore = useUserStore()
  const promoStore = usePromoStore()
  const accessToken = useCookie('access-token')

  onServerPrefetch(() => {
    if (accessToken.value) {
      userStore.getUser()
      subscribesStore.get()
    } else {
      isUserSubsriptionLoaded.value = true
    }

    promoStore.getPromo()

    $theme.getTheme()
  })

  if (accessToken.value) {
    await useAsyncData(
      'getUserInfo',
      () => userStore.getUserInfo()
    )
  }

  useAsyncData(
    'getActiveSubscriptions',
    () => subscribesStore.getActiveSubscriptions()
  )

  // TODO Временное решение, вообще такие вещи скорее всего должны отправляться через gtm
  onBeforeMount(() => {
    const { cl_uid: clUidParam, utm_source: utmSourceParam } = route.query
    const clUid = useCookie<string>('x_cl_uid', {
      maxAge: 90 * 24 * 60 * 60 // 90 дней
    })
    const utmSource = useCookie<string>('x_utm_source', {
      maxAge: 90 * 24 * 60 * 60 // 90 дней
    })

    if (clUidParam) {
      clUid.value = clUidParam as string
    }

    if (utmSourceParam) {
      utmSource.value = utmSourceParam as string
    }

    $theme.getTheme()
  })

  useHead({
    link: [
      { rel: 'apple-touch-icon', sizes: '180x180', href: `/favicon/${config.public.enviroment}/apple-touch-icon.png` },
      { rel: 'icon', type: 'image/png', sizes: '32x32', href: `/favicon/${config.public.enviroment}/favicon-32x32.png` },
      { rel: 'icon', type: 'image/png', sizes: '16x16', href: `/favicon/${config.public.enviroment}/favicon-16x16.png` },
      { rel: 'icon', href: `/favicon/${config.public.enviroment}/favicon.ico` },
      { rel: 'manifest', href: `/favicon/${config.public.enviroment}/site.webmanifest` },
    ]
  })
</script>
