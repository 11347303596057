<template>
  <svg aria-hidden="true">
    <use :xlink:href="`#${svgUseId}`" />
  </svg>
</template>

<script setup lang="ts">
  const props = defineProps<{
    name: string,
    viewBox?: string,
  }>()

  const svgUseId = computed(() => {
    return props.name.replaceAll('/', '--')
  })
</script>
