<template>
  <div class="error-page">
    <UiHeader
      logo-link="/"
      only-logo
      class="error-page__header"
    />

    <UiErrorBlock
      :status-code="error.statusCode"
      button-link="https://academika.ru"
    />

    <a
      href="mailto:info@akademika.ru"
      class="error-page__link f f--2 f--m"
    >
      info@academika.ru
    </a>
  </div>

  <div hidden v-html="SvgSprite" />
</template>

<script setup lang="ts">
  import UiHeader from '@academica-box/components/Blocks/UiHeader/UiHeader.vue'
  import UiErrorBlock from '@academica-box/components/Blocks/UiErrorBlock/UiErrorBlock.vue'

  import { useUserStore } from '@academica-box/composables/store/user'

  import { code403, code404, code500 } from '@/data/errors'
  import SvgSprite from '@/assets/svg/sprite.svg?raw'

  const props = defineProps<{
    error: {
      message: string;
      stack: string;
      statusCode: number;
      statusMessage: string;
      link: string;
    }
  }>()

  const { $theme } = useNuxtApp()

  const userStore = useUserStore()

  const accessToken = useCookie('access-token')

  const data = ref({
    title: '',
    description: '',
    images: {},
  })

  if (props.error.statusCode === 403) {
    data.value = code403
  }

  if (props.error.statusCode === 404) {
    data.value = code404
  }

  if (props.error.statusCode >= 500 && props.error.statusCode <= 599) {
    data.value = code500
  }

  onServerPrefetch(() => {
    if (accessToken.value) {
      userStore.getUser()
    }

    $theme.getTheme()
  })

  if (accessToken.value) {
    await useAsyncData(
      'getUserInfo',
      () => userStore.getUserInfo()
    )
  }

  onMounted(() => {
    console.error(props.error)
  })

  useHead({
    title: `${data.value.title} | Академика`,
  })
</script>

<style lang="scss">
  @use '@academica-box/utils/styles/mixins/UiMedia' as *;

  .error-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding-bottom: 36px;

    @include media(lg) {
      padding-bottom: 64px;
    }
  }

  .error-page__link {
    align-self: center;
    color: var(--text-main-color);
    text-decoration: none;
  }
</style>
