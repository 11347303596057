import { getUiPictureData } from '@academica-box/helpers/getUiPictureData'
import { DefaultApi } from '@academica-box/swagger/marketplace'
import { useAxios } from '@academica-box/composables/axios'
import type { FaqFields, SpeakersFields } from '@academica-box/swagger/marketplace'
import type { Spoiler } from '@academica-box/components/InteractiveElements/UiSpoiler/types'
import type { AuthorCard } from '@academica-box/components/Cards/UiAuthorCard/types'

export type FaqBlock = {
  title: string,
  items: Spoiler[],
}

export type AuthorsBlock = {
  title: string,
  description: string,
  items: AuthorCard[],
}

type GetMainPageResponse = {
  faqBlock: FaqBlock | null,
  authorsBlock: AuthorsBlock | null,
}

export default () => {
  const config = useRuntimeConfig()
  const axios = useAxios()
  const defaultApi = new DefaultApi(undefined, `${config.public.marketplaceApiUrl}/api`, axios)

  return {
    getMainPage(): Promise<GetMainPageResponse> {
      return defaultApi.v1RuMainGet().then((res) => {
        const faq = res.data.data.blocks.faq as FaqFields | undefined
        const speakers = res.data.data.blocks.speakers as SpeakersFields | undefined
        const result: GetMainPageResponse = {
          faqBlock: null,
          authorsBlock: null,
        }

        if (faq?.is_visible && faq.fields?.faq) {
          result.faqBlock = {
            title: faq.fields?.title || 'Часто задаваемые вопросы',
            items: faq.fields?.faq.map<Spoiler>((item) => {
              return {
                title: item.question,
                description: item.answer,
              }
            })
          }
        }

        if (speakers?.is_visible && speakers.fields?.speakers) {
          result.authorsBlock = {
            title: speakers.fields.title,
            description: speakers.fields.description,
            items: speakers.fields.speakers.map((item) => {
              return {
                author: {
                  images: item.image,
                  name: item.name,
                  position: item.description,
                  description: '',
                },
                vendor: {
                  text: item.company.abbreviation || '',
                  images: [
                    getUiPictureData({ sm: [item.company.logo] })
                  ]
                },
              }
            }),
          }
        }

        return result
      })
    },
  }
}
