import { CompaniesApi, LandingsApi } from '@academica-box/swagger/partnergen'
import type { Company } from '@academica-box/swagger/partnergen'

import { DefaultApi } from '@academica-box/swagger/academgen'
import { CompaniesApi as MarketplaceCompaniesApi, type CompanyCarouselItem, type GetCompaniesCarousel200Response } from '@academica-box/swagger/marketplace'
import { useAxios } from '@academica-box/composables/axios'
import { getAll } from '@/helpers/mappingVendorData'

export type MappedVendorLanding = ReturnType<typeof getAll>
export type CompaniesResponseData = { [key: string]: Array<Company>}
export type PartnersCarouselData = GetCompaniesCarousel200Response['data']
export type PartnersCarouselItem = CompanyCarouselItem
export type CompanyType = Company
export type RequestpartnershipParams = {
  email: string,
  name: string,
  company: string,
  position: string,
  phone: string,
  comment: string,
  recaptcha_token: string,
}

export default () => {
  const config = useRuntimeConfig()
  const axios = useAxios()
  const landingsApi = new LandingsApi(undefined, `${config.public.partnergenApiUrl}/api`, axios)
  const companiesApi = new CompaniesApi(undefined, `${config.public.partnergenApiUrl}/api`, axios)
  const defaultApi = new DefaultApi(undefined, `${config.public.academgenApiUrl}/api`, axios)
  const marketplaceCompaniesApi = new MarketplaceCompaniesApi(undefined, `${config.public.marketplaceApiUrl}/api`, axios)

  return {
    getCompanies() {
      return companiesApi.v1CompaniesGet().then((res) => {
        // TODO попросить на беке поправить тип
        return res.data as unknown as CompaniesResponseData
      })
    },

    getPartnersCarousel() {
      return marketplaceCompaniesApi.getCompaniesCarousel().then((res) => {
        return res.data.data
      })
    },

    getLanding(landingSlug: string) {
      return landingsApi.v1RuLandingsLandingSlugGet({ landingSlug }).then((res) => {
        return getAll(res.data.data)
      }).catch((error) => {
        if (error.response.status) {
          throw showError({ statusCode: error.response.status, statusMessage: error.response.statusText })
        }

        throw showError({ statusCode: error.status, statusMessage: error.statusText })
      })
    },

    requestPartnership(data: RequestpartnershipParams) {
      const validData = { ...data }
      const keys = (Object.keys(validData) as (keyof RequestpartnershipParams)[])

      keys.forEach((key) => {
        if (validData[key] === '') {
          delete validData[key]
        }
      })

      return defaultApi.partnership({ partnershipRequest: validData }).then((res) => {
        return res.data
      })
    }
  }
}
