import { default as indexYOff6xXt44Meta } from "/builds/web-dev/front/academica/academica/pages/analytics/index.ts?macro=true";
import { default as authuK9w4gPGjsMeta } from "/builds/web-dev/front/academica/academica/pages/auth.vue?macro=true";
import { default as blankwVfkqXZYbcMeta } from "/builds/web-dev/front/academica/academica/pages/blank.vue?macro=true";
import { default as _91_46_46_46slugs_93fFJvBQfH6NMeta } from "/builds/web-dev/front/academica/academica/pages/catalog/[...slugs].vue?macro=true";
import { default as indexBQa7iDHXYrMeta } from "/builds/web-dev/front/academica/academica/pages/catalog/analytics/index.ts?macro=true";
import { default as _91id_93tbnEtQNZPsMeta } from "/builds/web-dev/front/academica/academica/pages/cert/[id].vue?macro=true";
import { default as indexEeHiiW0GYkMeta } from "/builds/web-dev/front/academica/academica/pages/cert/index.vue?macro=true";
import { default as indexOPperXlpskMeta } from "/builds/web-dev/front/academica/academica/pages/contacts/index.vue?macro=true";
import { default as mocksBQ5aOzhZjAMeta } from "/builds/web-dev/front/academica/academica/pages/contacts/mocks.ts?macro=true";
import { default as indexrA6gl6gtLVMeta } from "/builds/web-dev/front/academica/academica/pages/dev-skillbox-pro/index.vue?macro=true";
import { default as indexkNAq00MpMJMeta } from "/builds/web-dev/front/academica/academica/pages/index/analytics/index.ts?macro=true";
import { default as ShelfCoursesUnder1000sdGsMMtL6uMeta } from "/builds/web-dev/front/academica/academica/pages/index/components/ShelfCoursesUnder1000.vue?macro=true";
import { default as ShelfFastStart8tslxozU5bMeta } from "/builds/web-dev/front/academica/academica/pages/index/components/ShelfFastStart.vue?macro=true";
import { default as ShelfFreeCoursesYlD1NtQZE1Meta } from "/builds/web-dev/front/academica/academica/pages/index/components/ShelfFreeCourses.vue?macro=true";
import { default as ShelfNewCoursesWqkFUzPYGEMeta } from "/builds/web-dev/front/academica/academica/pages/index/components/ShelfNewCourses.vue?macro=true";
import { default as ShelfUniversityCoursesX2N1656DxbMeta } from "/builds/web-dev/front/academica/academica/pages/index/components/ShelfUniversityCourses.vue?macro=true";
import { default as indexHOtIYsRMjmMeta } from "/builds/web-dev/front/academica/academica/pages/index/index.vue?macro=true";
import { default as loginC8I9QtiTZlMeta } from "/builds/web-dev/front/academica/academica/pages/login.vue?macro=true";
import { default as mocksDEOhBMSpNfMeta } from "/builds/web-dev/front/academica/academica/pages/mocks.ts?macro=true";
import { default as indexG13terDWtKMeta } from "/builds/web-dev/front/academica/academica/pages/partnership/index.vue?macro=true";
import { default as mocksHyvGcQGnvSMeta } from "/builds/web-dev/front/academica/academica/pages/partnership/mocks.ts?macro=true";
import { default as indexdLQOzA5r8zMeta } from "/builds/web-dev/front/academica/academica/pages/pro/index.vue?macro=true";
import { default as mocksD2RQS4l206Meta } from "/builds/web-dev/front/academica/academica/pages/pro/mocks.ts?macro=true";
import { default as rnd_45authxDBYe2jt7BMeta } from "/builds/web-dev/front/academica/academica/pages/rnd-auth.vue?macro=true";
import { default as index2TVFqeUnXzMeta } from "/builds/web-dev/front/academica/academica/pages/skillbox-design-library/index.vue?macro=true";
import { default as _91vendor_93jakoSJowYlMeta } from "/builds/web-dev/front/academica/academica/pages/vendor/[vendor].vue?macro=true";
import { default as index7UwOaEjswhMeta } from "/builds/web-dev/front/academica/academica/pages/vendor/index.vue?macro=true";
import { default as index5q8sFfeMbjMeta } from "/builds/web-dev/front/academica/academica/pages/vendors/index.vue?macro=true";
export default [
  {
    name: indexYOff6xXt44Meta?.name ?? "analytics___ru",
    path: indexYOff6xXt44Meta?.path ?? "/analytics",
    meta: indexYOff6xXt44Meta || {},
    alias: indexYOff6xXt44Meta?.alias || [],
    redirect: indexYOff6xXt44Meta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/analytics/index.ts").then(m => m.default || m)
  },
  {
    name: indexYOff6xXt44Meta?.name ?? "analytics___kz",
    path: indexYOff6xXt44Meta?.path ?? "/kz/analytics",
    meta: indexYOff6xXt44Meta || {},
    alias: indexYOff6xXt44Meta?.alias || [],
    redirect: indexYOff6xXt44Meta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/analytics/index.ts").then(m => m.default || m)
  },
  {
    name: authuK9w4gPGjsMeta?.name ?? "auth___ru",
    path: authuK9w4gPGjsMeta?.path ?? "/auth",
    meta: authuK9w4gPGjsMeta || {},
    alias: authuK9w4gPGjsMeta?.alias || [],
    redirect: authuK9w4gPGjsMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: authuK9w4gPGjsMeta?.name ?? "auth___kz",
    path: authuK9w4gPGjsMeta?.path ?? "/kz/auth",
    meta: authuK9w4gPGjsMeta || {},
    alias: authuK9w4gPGjsMeta?.alias || [],
    redirect: authuK9w4gPGjsMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: blankwVfkqXZYbcMeta?.name ?? "blank___ru",
    path: blankwVfkqXZYbcMeta?.path ?? "/blank",
    meta: blankwVfkqXZYbcMeta || {},
    alias: blankwVfkqXZYbcMeta?.alias || [],
    redirect: blankwVfkqXZYbcMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: blankwVfkqXZYbcMeta?.name ?? "blank___kz",
    path: blankwVfkqXZYbcMeta?.path ?? "/kz/blank",
    meta: blankwVfkqXZYbcMeta || {},
    alias: blankwVfkqXZYbcMeta?.alias || [],
    redirect: blankwVfkqXZYbcMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/blank.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93fFJvBQfH6NMeta?.name ?? "catalog-slugs___ru",
    path: _91_46_46_46slugs_93fFJvBQfH6NMeta?.path ?? "/catalog/:slugs(.*)*",
    meta: _91_46_46_46slugs_93fFJvBQfH6NMeta || {},
    alias: _91_46_46_46slugs_93fFJvBQfH6NMeta?.alias || [],
    redirect: _91_46_46_46slugs_93fFJvBQfH6NMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/catalog/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slugs_93fFJvBQfH6NMeta?.name ?? "catalog-slugs___kz",
    path: _91_46_46_46slugs_93fFJvBQfH6NMeta?.path ?? "/kz/catalog/:slugs(.*)*",
    meta: _91_46_46_46slugs_93fFJvBQfH6NMeta || {},
    alias: _91_46_46_46slugs_93fFJvBQfH6NMeta?.alias || [],
    redirect: _91_46_46_46slugs_93fFJvBQfH6NMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/catalog/[...slugs].vue").then(m => m.default || m)
  },
  {
    name: indexBQa7iDHXYrMeta?.name ?? "catalog-analytics___ru",
    path: indexBQa7iDHXYrMeta?.path ?? "/catalog/analytics",
    meta: indexBQa7iDHXYrMeta || {},
    alias: indexBQa7iDHXYrMeta?.alias || [],
    redirect: indexBQa7iDHXYrMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/catalog/analytics/index.ts").then(m => m.default || m)
  },
  {
    name: indexBQa7iDHXYrMeta?.name ?? "catalog-analytics___kz",
    path: indexBQa7iDHXYrMeta?.path ?? "/kz/catalog/analytics",
    meta: indexBQa7iDHXYrMeta || {},
    alias: indexBQa7iDHXYrMeta?.alias || [],
    redirect: indexBQa7iDHXYrMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/catalog/analytics/index.ts").then(m => m.default || m)
  },
  {
    name: _91id_93tbnEtQNZPsMeta?.name ?? "cert-id___ru",
    path: _91id_93tbnEtQNZPsMeta?.path ?? "/cert/:id()",
    meta: _91id_93tbnEtQNZPsMeta || {},
    alias: _91id_93tbnEtQNZPsMeta?.alias || [],
    redirect: _91id_93tbnEtQNZPsMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/cert/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tbnEtQNZPsMeta?.name ?? "cert-id___kz",
    path: _91id_93tbnEtQNZPsMeta?.path ?? "/kz/cert/:id()",
    meta: _91id_93tbnEtQNZPsMeta || {},
    alias: _91id_93tbnEtQNZPsMeta?.alias || [],
    redirect: _91id_93tbnEtQNZPsMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/cert/[id].vue").then(m => m.default || m)
  },
  {
    name: indexEeHiiW0GYkMeta?.name ?? "cert___ru",
    path: indexEeHiiW0GYkMeta?.path ?? "/cert",
    meta: indexEeHiiW0GYkMeta || {},
    alias: indexEeHiiW0GYkMeta?.alias || [],
    redirect: indexEeHiiW0GYkMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/cert/index.vue").then(m => m.default || m)
  },
  {
    name: indexEeHiiW0GYkMeta?.name ?? "cert___kz",
    path: indexEeHiiW0GYkMeta?.path ?? "/kz/cert",
    meta: indexEeHiiW0GYkMeta || {},
    alias: indexEeHiiW0GYkMeta?.alias || [],
    redirect: indexEeHiiW0GYkMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/cert/index.vue").then(m => m.default || m)
  },
  {
    name: indexOPperXlpskMeta?.name ?? "contacts___ru",
    path: indexOPperXlpskMeta?.path ?? "/contacts",
    meta: indexOPperXlpskMeta || {},
    alias: indexOPperXlpskMeta?.alias || [],
    redirect: indexOPperXlpskMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexOPperXlpskMeta?.name ?? "contacts___kz",
    path: indexOPperXlpskMeta?.path ?? "/kz/contacts",
    meta: indexOPperXlpskMeta || {},
    alias: indexOPperXlpskMeta?.alias || [],
    redirect: indexOPperXlpskMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/contacts/index.vue").then(m => m.default || m)
  },
  {
    name: mocksBQ5aOzhZjAMeta?.name ?? "contacts-mocks___ru",
    path: mocksBQ5aOzhZjAMeta?.path ?? "/contacts/mocks",
    meta: mocksBQ5aOzhZjAMeta || {},
    alias: mocksBQ5aOzhZjAMeta?.alias || [],
    redirect: mocksBQ5aOzhZjAMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/contacts/mocks.ts").then(m => m.default || m)
  },
  {
    name: mocksBQ5aOzhZjAMeta?.name ?? "contacts-mocks___kz",
    path: mocksBQ5aOzhZjAMeta?.path ?? "/kz/contacts/mocks",
    meta: mocksBQ5aOzhZjAMeta || {},
    alias: mocksBQ5aOzhZjAMeta?.alias || [],
    redirect: mocksBQ5aOzhZjAMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/contacts/mocks.ts").then(m => m.default || m)
  },
  {
    name: indexrA6gl6gtLVMeta?.name ?? "dev-skillbox-pro___ru",
    path: indexrA6gl6gtLVMeta?.path ?? "/dev-skillbox-pro",
    meta: indexrA6gl6gtLVMeta || {},
    alias: indexrA6gl6gtLVMeta?.alias || [],
    redirect: indexrA6gl6gtLVMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/dev-skillbox-pro/index.vue").then(m => m.default || m)
  },
  {
    name: indexrA6gl6gtLVMeta?.name ?? "dev-skillbox-pro___kz",
    path: indexrA6gl6gtLVMeta?.path ?? "/kz/dev-skillbox-pro",
    meta: indexrA6gl6gtLVMeta || {},
    alias: indexrA6gl6gtLVMeta?.alias || [],
    redirect: indexrA6gl6gtLVMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/dev-skillbox-pro/index.vue").then(m => m.default || m)
  },
  {
    name: indexkNAq00MpMJMeta?.name ?? "index-analytics___ru",
    path: indexkNAq00MpMJMeta?.path ?? "//analytics",
    meta: indexkNAq00MpMJMeta || {},
    alias: indexkNAq00MpMJMeta?.alias || [],
    redirect: indexkNAq00MpMJMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/analytics/index.ts").then(m => m.default || m)
  },
  {
    name: indexkNAq00MpMJMeta?.name ?? "index-analytics___kz",
    path: indexkNAq00MpMJMeta?.path ?? "/kz//analytics",
    meta: indexkNAq00MpMJMeta || {},
    alias: indexkNAq00MpMJMeta?.alias || [],
    redirect: indexkNAq00MpMJMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/analytics/index.ts").then(m => m.default || m)
  },
  {
    name: ShelfCoursesUnder1000sdGsMMtL6uMeta?.name ?? "index-components-ShelfCoursesUnder1000___ru",
    path: ShelfCoursesUnder1000sdGsMMtL6uMeta?.path ?? "//components/ShelfCoursesUnder1000",
    meta: ShelfCoursesUnder1000sdGsMMtL6uMeta || {},
    alias: ShelfCoursesUnder1000sdGsMMtL6uMeta?.alias || [],
    redirect: ShelfCoursesUnder1000sdGsMMtL6uMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfCoursesUnder1000.vue").then(m => m.default || m)
  },
  {
    name: ShelfCoursesUnder1000sdGsMMtL6uMeta?.name ?? "index-components-ShelfCoursesUnder1000___kz",
    path: ShelfCoursesUnder1000sdGsMMtL6uMeta?.path ?? "/kz//components/ShelfCoursesUnder1000",
    meta: ShelfCoursesUnder1000sdGsMMtL6uMeta || {},
    alias: ShelfCoursesUnder1000sdGsMMtL6uMeta?.alias || [],
    redirect: ShelfCoursesUnder1000sdGsMMtL6uMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfCoursesUnder1000.vue").then(m => m.default || m)
  },
  {
    name: ShelfFastStart8tslxozU5bMeta?.name ?? "index-components-ShelfFastStart___ru",
    path: ShelfFastStart8tslxozU5bMeta?.path ?? "//components/ShelfFastStart",
    meta: ShelfFastStart8tslxozU5bMeta || {},
    alias: ShelfFastStart8tslxozU5bMeta?.alias || [],
    redirect: ShelfFastStart8tslxozU5bMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfFastStart.vue").then(m => m.default || m)
  },
  {
    name: ShelfFastStart8tslxozU5bMeta?.name ?? "index-components-ShelfFastStart___kz",
    path: ShelfFastStart8tslxozU5bMeta?.path ?? "/kz//components/ShelfFastStart",
    meta: ShelfFastStart8tslxozU5bMeta || {},
    alias: ShelfFastStart8tslxozU5bMeta?.alias || [],
    redirect: ShelfFastStart8tslxozU5bMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfFastStart.vue").then(m => m.default || m)
  },
  {
    name: ShelfFreeCoursesYlD1NtQZE1Meta?.name ?? "index-components-ShelfFreeCourses___ru",
    path: ShelfFreeCoursesYlD1NtQZE1Meta?.path ?? "//components/ShelfFreeCourses",
    meta: ShelfFreeCoursesYlD1NtQZE1Meta || {},
    alias: ShelfFreeCoursesYlD1NtQZE1Meta?.alias || [],
    redirect: ShelfFreeCoursesYlD1NtQZE1Meta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfFreeCourses.vue").then(m => m.default || m)
  },
  {
    name: ShelfFreeCoursesYlD1NtQZE1Meta?.name ?? "index-components-ShelfFreeCourses___kz",
    path: ShelfFreeCoursesYlD1NtQZE1Meta?.path ?? "/kz//components/ShelfFreeCourses",
    meta: ShelfFreeCoursesYlD1NtQZE1Meta || {},
    alias: ShelfFreeCoursesYlD1NtQZE1Meta?.alias || [],
    redirect: ShelfFreeCoursesYlD1NtQZE1Meta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfFreeCourses.vue").then(m => m.default || m)
  },
  {
    name: ShelfNewCoursesWqkFUzPYGEMeta?.name ?? "index-components-ShelfNewCourses___ru",
    path: ShelfNewCoursesWqkFUzPYGEMeta?.path ?? "//components/ShelfNewCourses",
    meta: ShelfNewCoursesWqkFUzPYGEMeta || {},
    alias: ShelfNewCoursesWqkFUzPYGEMeta?.alias || [],
    redirect: ShelfNewCoursesWqkFUzPYGEMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfNewCourses.vue").then(m => m.default || m)
  },
  {
    name: ShelfNewCoursesWqkFUzPYGEMeta?.name ?? "index-components-ShelfNewCourses___kz",
    path: ShelfNewCoursesWqkFUzPYGEMeta?.path ?? "/kz//components/ShelfNewCourses",
    meta: ShelfNewCoursesWqkFUzPYGEMeta || {},
    alias: ShelfNewCoursesWqkFUzPYGEMeta?.alias || [],
    redirect: ShelfNewCoursesWqkFUzPYGEMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfNewCourses.vue").then(m => m.default || m)
  },
  {
    name: ShelfUniversityCoursesX2N1656DxbMeta?.name ?? "index-components-ShelfUniversityCourses___ru",
    path: ShelfUniversityCoursesX2N1656DxbMeta?.path ?? "//components/ShelfUniversityCourses",
    meta: ShelfUniversityCoursesX2N1656DxbMeta || {},
    alias: ShelfUniversityCoursesX2N1656DxbMeta?.alias || [],
    redirect: ShelfUniversityCoursesX2N1656DxbMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfUniversityCourses.vue").then(m => m.default || m)
  },
  {
    name: ShelfUniversityCoursesX2N1656DxbMeta?.name ?? "index-components-ShelfUniversityCourses___kz",
    path: ShelfUniversityCoursesX2N1656DxbMeta?.path ?? "/kz//components/ShelfUniversityCourses",
    meta: ShelfUniversityCoursesX2N1656DxbMeta || {},
    alias: ShelfUniversityCoursesX2N1656DxbMeta?.alias || [],
    redirect: ShelfUniversityCoursesX2N1656DxbMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/components/ShelfUniversityCourses.vue").then(m => m.default || m)
  },
  {
    name: indexHOtIYsRMjmMeta?.name ?? "index___ru",
    path: indexHOtIYsRMjmMeta?.path ?? "/",
    meta: indexHOtIYsRMjmMeta || {},
    alias: indexHOtIYsRMjmMeta?.alias || [],
    redirect: indexHOtIYsRMjmMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: indexHOtIYsRMjmMeta?.name ?? "index___kz",
    path: indexHOtIYsRMjmMeta?.path ?? "/kz",
    meta: indexHOtIYsRMjmMeta || {},
    alias: indexHOtIYsRMjmMeta?.alias || [],
    redirect: indexHOtIYsRMjmMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/index/index.vue").then(m => m.default || m)
  },
  {
    name: loginC8I9QtiTZlMeta?.name ?? "login___ru",
    path: loginC8I9QtiTZlMeta?.path ?? "/login",
    meta: loginC8I9QtiTZlMeta || {},
    alias: loginC8I9QtiTZlMeta?.alias || [],
    redirect: loginC8I9QtiTZlMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginC8I9QtiTZlMeta?.name ?? "login___kz",
    path: loginC8I9QtiTZlMeta?.path ?? "/kz/login",
    meta: loginC8I9QtiTZlMeta || {},
    alias: loginC8I9QtiTZlMeta?.alias || [],
    redirect: loginC8I9QtiTZlMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/login.vue").then(m => m.default || m)
  },
  {
    name: mocksDEOhBMSpNfMeta?.name ?? "mocks___ru",
    path: mocksDEOhBMSpNfMeta?.path ?? "/mocks",
    meta: mocksDEOhBMSpNfMeta || {},
    alias: mocksDEOhBMSpNfMeta?.alias || [],
    redirect: mocksDEOhBMSpNfMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/mocks.ts").then(m => m.default || m)
  },
  {
    name: mocksDEOhBMSpNfMeta?.name ?? "mocks___kz",
    path: mocksDEOhBMSpNfMeta?.path ?? "/kz/mocks",
    meta: mocksDEOhBMSpNfMeta || {},
    alias: mocksDEOhBMSpNfMeta?.alias || [],
    redirect: mocksDEOhBMSpNfMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/mocks.ts").then(m => m.default || m)
  },
  {
    name: indexG13terDWtKMeta?.name ?? "partnership___ru",
    path: indexG13terDWtKMeta?.path ?? "/partnership",
    meta: indexG13terDWtKMeta || {},
    alias: indexG13terDWtKMeta?.alias || [],
    redirect: indexG13terDWtKMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/partnership/index.vue").then(m => m.default || m)
  },
  {
    name: indexG13terDWtKMeta?.name ?? "partnership___kz",
    path: indexG13terDWtKMeta?.path ?? "/kz/partnership",
    meta: indexG13terDWtKMeta || {},
    alias: indexG13terDWtKMeta?.alias || [],
    redirect: indexG13terDWtKMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/partnership/index.vue").then(m => m.default || m)
  },
  {
    name: mocksHyvGcQGnvSMeta?.name ?? "partnership-mocks___ru",
    path: mocksHyvGcQGnvSMeta?.path ?? "/partnership/mocks",
    meta: mocksHyvGcQGnvSMeta || {},
    alias: mocksHyvGcQGnvSMeta?.alias || [],
    redirect: mocksHyvGcQGnvSMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/partnership/mocks.ts").then(m => m.default || m)
  },
  {
    name: mocksHyvGcQGnvSMeta?.name ?? "partnership-mocks___kz",
    path: mocksHyvGcQGnvSMeta?.path ?? "/kz/partnership/mocks",
    meta: mocksHyvGcQGnvSMeta || {},
    alias: mocksHyvGcQGnvSMeta?.alias || [],
    redirect: mocksHyvGcQGnvSMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/partnership/mocks.ts").then(m => m.default || m)
  },
  {
    name: indexdLQOzA5r8zMeta?.name ?? "pro___ru",
    path: indexdLQOzA5r8zMeta?.path ?? "/pro",
    meta: indexdLQOzA5r8zMeta || {},
    alias: indexdLQOzA5r8zMeta?.alias || [],
    redirect: indexdLQOzA5r8zMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/pro/index.vue").then(m => m.default || m)
  },
  {
    name: indexdLQOzA5r8zMeta?.name ?? "pro___kz",
    path: indexdLQOzA5r8zMeta?.path ?? "/kz/pro",
    meta: indexdLQOzA5r8zMeta || {},
    alias: indexdLQOzA5r8zMeta?.alias || [],
    redirect: indexdLQOzA5r8zMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/pro/index.vue").then(m => m.default || m)
  },
  {
    name: mocksD2RQS4l206Meta?.name ?? "pro-mocks___ru",
    path: mocksD2RQS4l206Meta?.path ?? "/pro/mocks",
    meta: mocksD2RQS4l206Meta || {},
    alias: mocksD2RQS4l206Meta?.alias || [],
    redirect: mocksD2RQS4l206Meta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/pro/mocks.ts").then(m => m.default || m)
  },
  {
    name: mocksD2RQS4l206Meta?.name ?? "pro-mocks___kz",
    path: mocksD2RQS4l206Meta?.path ?? "/kz/pro/mocks",
    meta: mocksD2RQS4l206Meta || {},
    alias: mocksD2RQS4l206Meta?.alias || [],
    redirect: mocksD2RQS4l206Meta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/pro/mocks.ts").then(m => m.default || m)
  },
  {
    name: rnd_45authxDBYe2jt7BMeta?.name ?? "rnd-auth___ru",
    path: rnd_45authxDBYe2jt7BMeta?.path ?? "/rnd-auth",
    meta: rnd_45authxDBYe2jt7BMeta || {},
    alias: rnd_45authxDBYe2jt7BMeta?.alias || [],
    redirect: rnd_45authxDBYe2jt7BMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/rnd-auth.vue").then(m => m.default || m)
  },
  {
    name: rnd_45authxDBYe2jt7BMeta?.name ?? "rnd-auth___kz",
    path: rnd_45authxDBYe2jt7BMeta?.path ?? "/kz/rnd-auth",
    meta: rnd_45authxDBYe2jt7BMeta || {},
    alias: rnd_45authxDBYe2jt7BMeta?.alias || [],
    redirect: rnd_45authxDBYe2jt7BMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/rnd-auth.vue").then(m => m.default || m)
  },
  {
    name: index2TVFqeUnXzMeta?.name ?? "skillbox-design-library___ru",
    path: index2TVFqeUnXzMeta?.path ?? "/skillbox-design-library",
    meta: index2TVFqeUnXzMeta || {},
    alias: index2TVFqeUnXzMeta?.alias || [],
    redirect: index2TVFqeUnXzMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/skillbox-design-library/index.vue").then(m => m.default || m)
  },
  {
    name: index2TVFqeUnXzMeta?.name ?? "skillbox-design-library___kz",
    path: index2TVFqeUnXzMeta?.path ?? "/kz/skillbox-design-library",
    meta: index2TVFqeUnXzMeta || {},
    alias: index2TVFqeUnXzMeta?.alias || [],
    redirect: index2TVFqeUnXzMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/skillbox-design-library/index.vue").then(m => m.default || m)
  },
  {
    name: _91vendor_93jakoSJowYlMeta?.name ?? "vendor-vendor___ru",
    path: _91vendor_93jakoSJowYlMeta?.path ?? "/vendor/:vendor()",
    meta: _91vendor_93jakoSJowYlMeta || {},
    alias: _91vendor_93jakoSJowYlMeta?.alias || [],
    redirect: _91vendor_93jakoSJowYlMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/vendor/[vendor].vue").then(m => m.default || m)
  },
  {
    name: _91vendor_93jakoSJowYlMeta?.name ?? "vendor-vendor___kz",
    path: _91vendor_93jakoSJowYlMeta?.path ?? "/kz/vendor/:vendor()",
    meta: _91vendor_93jakoSJowYlMeta || {},
    alias: _91vendor_93jakoSJowYlMeta?.alias || [],
    redirect: _91vendor_93jakoSJowYlMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/vendor/[vendor].vue").then(m => m.default || m)
  },
  {
    name: index7UwOaEjswhMeta?.name ?? "vendor___ru",
    path: index7UwOaEjswhMeta?.path ?? "/vendor",
    meta: index7UwOaEjswhMeta || {},
    alias: index7UwOaEjswhMeta?.alias || [],
    redirect: index7UwOaEjswhMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/vendor/index.vue").then(m => m.default || m)
  },
  {
    name: index7UwOaEjswhMeta?.name ?? "vendor___kz",
    path: index7UwOaEjswhMeta?.path ?? "/kz/vendor",
    meta: index7UwOaEjswhMeta || {},
    alias: index7UwOaEjswhMeta?.alias || [],
    redirect: index7UwOaEjswhMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/vendor/index.vue").then(m => m.default || m)
  },
  {
    name: index5q8sFfeMbjMeta?.name ?? "vendors___ru",
    path: index5q8sFfeMbjMeta?.path ?? "/vendors",
    meta: index5q8sFfeMbjMeta || {},
    alias: index5q8sFfeMbjMeta?.alias || [],
    redirect: index5q8sFfeMbjMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/vendors/index.vue").then(m => m.default || m)
  },
  {
    name: index5q8sFfeMbjMeta?.name ?? "vendors___kz",
    path: index5q8sFfeMbjMeta?.path ?? "/kz/vendors",
    meta: index5q8sFfeMbjMeta || {},
    alias: index5q8sFfeMbjMeta?.alias || [],
    redirect: index5q8sFfeMbjMeta?.redirect || undefined,
    component: () => import("/builds/web-dev/front/academica/academica/pages/vendors/index.vue").then(m => m.default || m)
  }
]