import { getUiPictureData } from '@academica-box/helpers/getUiPictureData'
import type { Images } from '@academica-box/components/Media/UiPicture/types'
import type { Author } from '@academica-box/components/Blocks/UiAuthor/types'
import type { AchievementCard } from '@academica-box/components/Cards/UiAchievementCard/types'
import type { Spoiler } from '@academica-box/components/InteractiveElements/UiSpoiler/types'
import { AboutFieldsFieldsPlayerTypeEnum, type Landing } from '@academica-box/swagger/partnergen'
import type { VendorAbout, VendorReview, VendorStartScreen } from '@/types/vendor'

export const getCommonBlock = (landing: Landing) => {
  return landing.common
}

type StartScreenBlock = VendorStartScreen | undefined

export const getStartScreenBlock = (landing: Landing): StartScreenBlock => {
  const block = landing.blocks.partner
  const commonBlock = landing.common

  if (!block?.is_visible) {
    return
  }

  return {
    card: {
      abbreviation: commonBlock.abbreviation || '',
      logo: getUiPictureData({ sm: [commonBlock.logo || ''] }),
      rating: {
        value: commonBlock.rating || 0,
        count: commonBlock.reviews_count,
        link: '', // TODO включить когда заработаеют отзывы
      }
    },
    images: block.fields?.image as Images,
    title: commonBlock.title,
    description: block.fields?.description || '',
    link: `/catalog/?companies=${landing.url}`,
    partnerWebsite: block.fields?.url || '',
  }
}

type FaqBlock = {
  title: string;
  faq: Spoiler[];
} | undefined

export const getFaqBlock = (landing: Landing): FaqBlock => {
  const block = landing.blocks.faq

  if (!block?.is_visible) {
    return
  }

  return {
    title: block.fields?.title || '',
    faq: block.fields?.faq.map(item => ({
      title: item.question,
      description: item.answer,
    })) || []
  }
}

type AboutBlock = {
  title: string;
  data: VendorAbout;
} | undefined

export const getAboutBlock = (landing: Landing): AboutBlock => {
  const block = landing.blocks.about

  if (!block?.is_visible) {
    return
  }

  let videoSrc = ''

  if (block.fields?.player_type === AboutFieldsFieldsPlayerTypeEnum.Youtube) {
    videoSrc = block.fields.video_id ? `https://www.youtube.com/embed/${block.fields.video_id}` : ''
  }

  if (block.fields?.player_type === AboutFieldsFieldsPlayerTypeEnum.Vk) {
    videoSrc = block.fields.video_id ? `https://vk.com/video_ext.php?${block.fields.video_id}&hd=2&js_api=1` : ''
  }

  return {
    title: block.fields?.title || '',
    data: {
      video_src: videoSrc,
      content: block.fields?.description || ''
    },
  }
}

type SpeakersBlock = {
  title: string;
  speakers: Author[];
} | undefined

export const getSpeakersBlock = (landing: Landing): SpeakersBlock => {
  const block = landing.blocks.teachers

  if (!block?.is_visible) {
    return
  }

  return {
    title: block.fields?.title || '',
    speakers: block.fields?.teachers.map(teacher => ({
      images: teacher.image as Images,
      name: teacher.name,
      position: teacher.job,
      description: teacher.teacher_description || '',
    })) || []
  }
}

export const getCoursesBlock = (landing: Landing) => {
  const block = landing.blocks.courses

  if (!block?.is_visible) {
    return
  }

  return {
    title: block.fields?.title || '',
  }
}

type ReviewsBlock = {
  reviews: VendorReview[];
  rating: number;
} | undefined

// TODO Привязать бэк
export const getReviewsBlock = (): ReviewsBlock => {
  return {
    rating: 3,
    reviews: []
  }
}

type AchievementsBlock = {
  title: string;
  achievements: AchievementCard[];
} | undefined

export const getAchievementsBlock = (landing: Landing): AchievementsBlock => {
  const block = landing.blocks.achievements

  if (!block?.is_visible) {
    return
  }

  return {
    title: block.fields?.title || '',
    achievements: block.fields?.achievements.map(achievement => ({
      ...achievement,
      images: achievement.image as Images
    })) || []
  }
}

export const getAll = (landing: Landing) => ({
  commonBlock: getCommonBlock(landing),
  startScreenBlock: getStartScreenBlock(landing),
  faqBlock: getFaqBlock(landing),
  aboutBlock: getAboutBlock(landing),
  speakersBlock: getSpeakersBlock(landing),
  coursesBlock: getCoursesBlock(landing),
  reviewsBlock: getReviewsBlock(),
  achievementsBlock: getAchievementsBlock(landing),
  rawData: landing,
})
