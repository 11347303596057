import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/browser'

interface ISentry {
  setContext: typeof Sentry.setContext;
  setUser: typeof Sentry.setUser;
  setTag: typeof Sentry.setTag;
  addBreadcrumb: typeof Sentry.addBreadcrumb;
  captureException: typeof Sentry.captureException;
}

declare module '#app' {
  interface NuxtApp {
    $sentry: ISentry;
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const { vueApp } = nuxtApp
  const config = useRuntimeConfig()
  const router = useRouter()

  Sentry.init({
    app: [vueApp],
    dsn: config.public.sentryDsn,
    environment: process.dev ? 'development' : 'production',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation({
          ...router,
          beforeEach: guard =>
            router.beforeEach((to, from, next = () => true) => {
              guard(to, from, next)
            }),
        }),
      }),
    ],
    tracesSampleRate: 1.0,
    beforeSend (event, hint) {
      if (event.exception) {
        console.error(
          `[Exception handled by Sentry]: ${hint.originalException}`,
          { event, hint }
        )
      }
      return event
    },
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    })
  )
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  })

  return {
    provide: {
      sentry: {
        setContext: Sentry.setContext,
        setUser: Sentry.setUser,
        setTag: Sentry.setTag,
        addBreadcrumb: Sentry.addBreadcrumb,
        captureException: (...args: Parameters<typeof Sentry.captureException>) => {
          const isDebug = useCookie('debug')

          if (isDebug.value) {
            console.error(args[0])
          } else {
            Sentry.captureException(...args)
          }
        },
      },
    },
  }
})
