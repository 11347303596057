import { getUiPictureData } from '@academica-box/helpers/getUiPictureData'

export const code403 = {
  title: 'Доступ запрещен',
  description: 'Извините, у вас нет прав на доступ к данному разделу.',
  images: getUiPictureData({
    sm: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/403-sm@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/403-sm@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/403-sm@1x.png',
    ],
    lg: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/403-lg@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/403-lg@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/403-lg@1x.png',
    ]
  }),
}

export const code404 = {
  title: 'Страница не найдена',
  description: 'Ссылка, по которой вы перешли, никуда не ведет. Возможно, в ней была опечатка, или эта страница удалена.',
  images: getUiPictureData({
    sm: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/404-sm@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/404-sm@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/404-sm@1x.png',
    ],
    lg: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/404-lg@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/404-lg@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/404-lg@1x.png',
    ]
  }),
}

export const code500 = {
  title: 'Ошибка сервера',
  description: 'Что-то пошло не так, попробуйте перезагрузить страницу.',
  images: getUiPictureData({
    sm: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/500-sm@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/500-sm@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/500-sm@1x.png',
    ],
    lg: [
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/500-lg@1x.avif',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/500-lg@1x.webp',
      'https://cdn.skillbox.pro/wbd-front/academica-static/errors/500-lg@1x.png',
    ]
  }),
}
