import { createLaravelPassportClient } from '@skillbox/passport-client'

declare module '#app' {
  interface NuxtApp {
    $passportClient: ReturnType<typeof createLaravelPassportClient>;
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const { $sentry } = useNuxtApp()

  const rndDomain = config.public.rndUrl

  let passportClient = null

  try {
    if (window.location.origin === rndDomain) {
      passportClient = createLaravelPassportClient({
        domain: config.public.passportRndDomain,
        client_id: config.public.passportClientId,
        redirect_uri: `${window.location.origin}/rnd-auth/`,
        scope: 'openid email profile roles permissions',
        storage: {
          type: 'localStorage',
          userTokenName: 'access-token',
          idTokenName: 'id-token',
          refreshTokenName: 'refresh-token'
        },
        popupsOptions: {
          passport: {
            width: 780,
            height: 800
          }
        }
      })
    } else {
      passportClient = createLaravelPassportClient({
        domain: config.public.passportDomain,
        client_id: config.public.passportClientId,
        redirect_uri: `${window.location.origin}/auth/`,
        scope: 'openid email profile roles permissions',
      })
    }
  } catch (error) {
    $sentry.captureException(error)
  }

  return {
    provide: {
      passportClient,
    },
  }
})
