import { AxiosError } from 'axios'
import { getUiPictureData } from '@academica-box/helpers/getUiPictureData'
import type { Images } from '@academica-box/components/Media/UiPicture/types'
import { V1CertificatesApi } from '@academica-box/swagger/certgen'
import type { ValidateCertificateResponse } from '@academica-box/swagger/certgen'
import { useAxios } from '@academica-box/composables/axios'

export type CertificateData = ValidateCertificateResponse & {
  image: Images;
}

export default () => {
  const config = useRuntimeConfig()
  const axios = useAxios()

  const defaultApi = new V1CertificatesApi(undefined, `${config.public.certgenApiUrl}`, axios)

  return {
    validate(certificateNumber: string): Promise<CertificateData> {
      if (useCookie('mock').value) {
        return new Promise<ValidateCertificateResponse>((resolve) => {
          resolve({
            full_name: 'John Doe',
            created_at: '1970-01-01',
            nomenclature_type: 'course',
            course_name: '(Архив) Digital-стратег',
            course_duration: 1,
            link: 'https://248006.selcdn.ru/CertGenDev/templates/QUJxk0Pksg4LB1GStyvxwT02qBAVdMtSe6IkgcD0_example.png',
          })
        }).then((res) => {
          return {
            ...res,
            image: getUiPictureData({ sm: [res.link || ''] })
          }
        })
      } else {
        return defaultApi.validateCertificate({ certificateNumber }).then((res) => {
          return {
            ...res.data,
            image: getUiPictureData({ sm: [res.data.link || ''] })
          }
        }).catch((error: AxiosError) => {
          throw showError({ statusCode: error.response?.status, statusMessage: error.message })
        })
      }
    },
  }
}
