import { getUiPictureData } from '@academica-box/helpers/getUiPictureData'
import { FiltersApi } from '@academica-box/swagger/academgen'
import type { FiltersApiGetFiltersRequest, GetFilters200ResponseSort } from '@academica-box/swagger/academgen'
import { useAxios } from '@academica-box/composables/axios'
import type { Filters } from '@/types'

export type SortFilter = FiltersApiGetFiltersRequest

export default () => {
  const config = useRuntimeConfig()
  const axios = useAxios()
  const filtersApi = new FiltersApi(undefined, `${config.public.academgenApiUrl}/api`, axios)

  return {
    get(sortFilter?: SortFilter) {
      return filtersApi.getFilters(
        sortFilter,
      ).then((res) => {
        // TODO: Либо упростить контракт в UI либе, либо попросить на беке поправить контракт
        const filters: Filters = {
          companies: Object.keys(res.data.companies || []).map(key => ({
            title: key,
            items: res.data.companies[key]?.map(item => ({
              images: undefined,
              text: item.abbreviation || item.name,
              value: item.slug,
            })) || []
          })),
          types: res.data.types.map(item => ({
            images: undefined,
            text: item.title || '',
            value: item.slug || '',
            disabled: Boolean(!item.count)
          })) || [],
          levels: res.data.levels.map(item => ({
            images: undefined,
            text: item.title,
            value: item.slug,
            disabled: Boolean(!item.count)
          })) || [],
          languages: res.data.languages.map(item => ({
            images: getUiPictureData({ sm: [item.icon] }),
            text: item.text,
            value: item.key,
            disabled: Boolean(!item.count)
          })) || [],
          certificate: res.data.certificate.map(item => ({
            images: undefined,
            text: item.title,
            value: '1',
            disabled: Boolean(!item.count)
          })) || [],
          duration: {
            labelMin: 'От, часов',
            labelMax: 'До, часов',
            nameMin: 'От, часов',
            nameMax: 'До, часов',
            placeholderMin: (res.data.duration.min || 0).toString(),
            placeholderMax: (res.data.duration.max || 0).toString(),
          },
          price: {
            labelMin: 'Мин. цена',
            labelMax: 'Макс. цена',
            nameMin: 'Мин. цена',
            nameMax: 'Макс. цена',
            placeholderMin: (res.data.price.min || 0).toString(),
            placeholderMax: (res.data.price.max || 0).toString(),
          },
          sorting: [
            {
              value: '',
              text: 'По умолчанию',
            },
            ...Object.keys(res.data.sort).map(key => ({
              value: key,
              text: res.data.sort[key as keyof GetFilters200ResponseSort] || '',
            }))
          ],
        }

        return filters
      })
    },
  }
}
