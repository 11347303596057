import { SubscriptionsApi } from '@academica-box/swagger/academgen'
import type { SubscriptionOpenPostBodyRequest } from '@academica-box/swagger/academgen'
import { useAxios } from '@academica-box/composables/axios'

export type SubscribtionOpenData = SubscriptionOpenPostBodyRequest

export default () => {
  const config = useRuntimeConfig()
  const axios = useAxios()

  const subscriptionsApi = new SubscriptionsApi(undefined, `${config.public.landgenApiUrl}/academika/api`, axios)

  return {
    openRndSubscription(data: SubscribtionOpenData) {
      return subscriptionsApi.openSubscription(
        { subscriptionOpenPostBodyRequest: data },
        { withCredentials: true }
      ).then((res) => {
        return res.data
      })
    },
  }
}
