import revive_payload_client_DTs6DOCklc from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt@3.9.0_eslint@8.37.0_rollup@4.16.4_sass@1.60.0_stylelint@16.1.0_typescript@5.4.5_vite@4.5.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_GIbrXnWEse from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt@3.9.0_eslint@8.37.0_rollup@4.16.4_sass@1.60.0_stylelint@16.1.0_typescript@5.4.5_vite@4.5.3_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Ghp9INQims from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt@3.9.0_eslint@8.37.0_rollup@4.16.4_sass@1.60.0_stylelint@16.1.0_typescript@5.4.5_vite@4.5.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_zyFlPdtjQ2 from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.0.6_@vue+compiler-core@3.4.25_axios@1.3.4_nuxt@3.9.0_igzeyhxsa7eism667de4nkfoeq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_xAKoY4iZwf from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt@3.9.0_eslint@8.37.0_rollup@4.16.4_sass@1.60.0_stylelint@16.1.0_typescript@5.4.5_vite@4.5.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_KSV4hF45wd from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt@3.9.0_eslint@8.37.0_rollup@4.16.4_sass@1.60.0_stylelint@16.1.0_typescript@5.4.5_vite@4.5.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_l9L35JtBnu from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/@pinia+nuxt@0.4.7_rollup@4.16.4_typescript@5.4.5_vue@3.4.25/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/web-dev/front/academica/academica/.nuxt/components.plugin.mjs";
import prefetch_client_rWeW6E4o0H from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt@3.9.0_eslint@8.37.0_rollup@4.16.4_sass@1.60.0_stylelint@16.1.0_typescript@5.4.5_vite@4.5.3_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_uWSOBUy3yc from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.9.0_rollup@4.16.4_vue@3.4.25/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import nuxt3_plugin_E6mTqUHCom from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/vue-yandex-maps@2.0.1_nuxt@3.9.0_vue@3.4.25/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import i18n_JgQZUQFiI1 from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.16.4_vue@3.4.25/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_client_2zm0zVoQmu from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt-viewport@2.1.4_rollup@4.16.4_vue@3.4.25/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import chunk_reload_client_QxtuqvNVY1 from "/builds/web-dev/front/academica/academica/node_modules/.pnpm/nuxt@3.9.0_eslint@8.37.0_rollup@4.16.4_sass@1.60.0_stylelint@16.1.0_typescript@5.4.5_vite@4.5.3_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_sentry_2snMPgo3X5 from "/builds/web-dev/front/academica/academica/plugins/01.sentry.ts";
import _02_passport_client_client_BFkfM9PqDB from "/builds/web-dev/front/academica/academica/plugins/02.passport-client.client.ts";
import _04_api_wkwmf31gNz from "/builds/web-dev/front/academica/academica/plugins/04.api.ts";
import analytics_client_mtqVw3YRXk from "/builds/web-dev/front/academica/academica/plugins/analytics.client.ts";
import bsl_XSiN7Ti2wl from "/builds/web-dev/front/academica/academica/plugins/bsl.ts";
import escape_bTvSgMEB7l from "/builds/web-dev/front/academica/academica/plugins/escape.ts";
import growthbook_client_4iB3FvliSu from "/builds/web-dev/front/academica/academica/plugins/growthbook.client.ts";
import isSafari_client_Tm5s0e6e2m from "/builds/web-dev/front/academica/academica/plugins/isSafari.client.ts";
import theme_zupea9J243 from "/builds/web-dev/front/academica/academica/plugins/theme.ts";
import userSession_client_UljhYSmrzY from "/builds/web-dev/front/academica/academica/plugins/userSession.client.ts";
export default [
  revive_payload_client_DTs6DOCklc,
  unhead_GIbrXnWEse,
  router_Ghp9INQims,
  _0_siteConfig_zyFlPdtjQ2,
  payload_client_xAKoY4iZwf,
  check_outdated_build_client_KSV4hF45wd,
  plugin_vue3_l9L35JtBnu,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rWeW6E4o0H,
  plugin_uWSOBUy3yc,
  nuxt3_plugin_E6mTqUHCom,
  i18n_JgQZUQFiI1,
  plugin_client_2zm0zVoQmu,
  chunk_reload_client_QxtuqvNVY1,
  _01_sentry_2snMPgo3X5,
  _02_passport_client_client_BFkfM9PqDB,
  _04_api_wkwmf31gNz,
  analytics_client_mtqVw3YRXk,
  bsl_XSiN7Ti2wl,
  escape_bTvSgMEB7l,
  growthbook_client_4iB3FvliSu,
  isSafari_client_Tm5s0e6e2m,
  theme_zupea9J243,
  userSession_client_UljhYSmrzY
]